
import en_tl from './pages/PatientsAndCaregivers/Languages/en.json';
import fr_tl from './pages/PatientsAndCaregivers/Languages/fr.json';
import ch_tl from './pages/PatientsAndCaregivers/Languages/ch.json';
import de_tl from './pages/PatientsAndCaregivers/Languages/de.json';
import it_tl from './pages/PatientsAndCaregivers/Languages/it.json';
import ne_tl from './pages/PatientsAndCaregivers/Languages/ne.json';
import es_tl from './pages/PatientsAndCaregivers/Languages/es.json';
import {FaAngleRight, FaRegBell} from "react-icons/fa";


export const US = "us";
export const OUT_OF_US = "out of us"

// product sections below
export const STORIES = "heart stories";
export const FEATURES = "features";
export const CLINICAL_EVIDENCE = "clinical evidence";
export const REFERENCES = "references";
export const PRODUCT_RESOURCES = "Resources";
export const MRI = "MRI";
export const PATIENT_INFORMATION = "Patient Information";
export const SAFETY_IFNORMATION = "safety information";
export const CONTACT_US = "contact us";

// patient and caregivers section below
export const AORTIC_STENOSIS = "aortic stenosis";
export const INR_MANAGEMENT = "inr management";
export const MITRAL_AWARENESS = "mitral insufficiency";

// patient and caregivers hash url
export const AORTIC_STENOSIS_HASH = "aortic-stenosis";
export const MITRAL_AWARENESS_HASH = "mitral-insufficiency";
export const STORIES_HASH = "heart-stories";
export const REFERENCES_HASH = "references";
export const PRODUCT_RESOURCES_HASH = "resources";
export const MRI_HASH = "mri";
export const PATIENT_INFORMATION_HASH = "patient-information";

// aortic stenosis tab constants below
export const HUMAN_HEART = {
    name:"HOW MUCH DO YOU KNOW ABOUT YOUR HEART?",
    id:"humanheart.tab.heading",
};
export const HEART_DISEASE = {
    name:"IF YOUR AORTIC VALVE DOESN’T WORK WELL",
    id:"humandisease.tab.heading",
};
export const TREATMENT = {
    name:"HOW CAN AORTIC VALVE DISEASE BE TREATED?",
    id:"treatment.tab.heading",
};
export const SURGERY = {
    name:"BEFORE, DURING AND AFTER THE SURGERY",
    id:"surgery.tab.heading",
};
export const FAQ = {
    name:"FREQUENTLY ASKED QUESTIONS",
    id:"faq.tab.heading",
};
export const GUIDE = {
    name:"PATIENT GUIDE BOOKLET",
    id:"guide.tab.heading",
};
// export const HUMAN_HEART = "HOW MUCH DO YOU KNOW ABOUT YOUR HEART?";
// export const HEART_DISEASE = "IF YOUR AORTIC VALVE DOESN’T WORK WELL";
// export const TREATMENT = "HOW CAN AORTIC VALVE DISEASE BE TREATED?";
// export const SURGERY = "BEFORE, DURING AND AFTER THE SURGERY";
// export const FAQ = "FREQUENTLY ASKED QUESTIONS";
// export const GUIDE = "PATIENT GUIDE BOOKLET";


// aortic stenosis tab constants below
export const MITRAL_AWARENESS_HUMAN_HEART = {
    name:"HOW MUCH DO YOU KNOW ABOUT YOUR HEART?",
    id:"mitral_awareness_humanheart_tab_heading",
};
export const MITRAL_AWARENESS_HEART_DISEASE = {
    name:"IF YOUR MITRAL VALVE DOESN’T WORK WELL",
    id:"mitral_awareness_humandisease_tab_heading",
};
// export const MITRAL_AWARENESS_SYMPTOMS = {
//     name:"WHAT ARE THE SYMPTOMS OF MITRAL VALVE DISEASE?",
//     id:"mitral_awareness_symptoms_tab_heading",
// };
export const MITRAL_AWARENESS_TREATMENT = {
    name:"HOW CAN MITRAL VALVE DISEASE BE TREATED?",
    id:"mitral_awareness_treatment_tab_heading",
};
// export const MITRAL_AWARENESS_TREATMENT_OPTIONS = {
//     name:"WHAT ARE THE OPTIONS TO TREAT MITRAL VALVE DISEASE?",
//     id:"mitral_awareness_treatment_tab_heading",
// };
// export const MITRAL_AWARENESS_SURGICAL_APPROACH = {
//     name:"WHAT ARE THE PROSTHESES USED IN THE SURGICAL APPROACH?",
//     id:"mitral_awareness_surgical_approch_tab_heading",
// };
export const MITRAL_AWARENESS_BEFORE_AFTER_SURGERY = {
    name:"BEFORE, DURING AND AFTER THE SURGERY",
    id:"mitral_awareness_before_after_surgery_tab_heading",
};
// export const MITRAL_AWARENESS_BEFORE_SURGERY = {
//     name:"WHAT WILL HAPPEN BEFORE SURGERY?",
//     id:"mitral_awareness_before_surgery_tab_heading",
// };
// export const MITRAL_AWARENESS_AFTER_SURGERY = {
//     name:"WHAT WILL HAPPEN AFTER SURGERY?",
//     id:"mitral_awareness_after_surgery_tab_heading",
// };
export const MITRAL_AWARENESS_FAQ = {
    name:"FREQUENTLY ASKED QUESTIONS",
    id:"mitral_awareness_faq_tab_heading",
};
export const MITRAL_AWARENESS_GUIDE = {
    name:"PATIENT GUIDE BOOKLET",
    id:"mitral_awareness_guide_tab_heading",
};


export const MINIMALLY_INVASIVE_INSTRUMENTS = "minimally invasive instruments";

export const defaultLanguageIndex = 6;

export const languagesArray = [
    //china
    {
        label:<div className="flex items-center"><img className="w-6 mr-2" src="/images/flags/flags2.png" /> 中国</div>, 
        value:{
            locale: "ch",
            messages: ch_tl,
            country: "中国",
            flag: "/images/flags/flags2.png"
        }
    },
    //germany
    {
        label:<div className="flex items-center"><img className="w-6 mr-2" src="/images/flags/flags3.png" /> DEUTSCHLAND</div>, 
        value:{
            locale: "de",
            messages: de_tl,
            country: "DEUTSCHLAND",
            flag: "/images/flags/flags3.png"
        }
    },
    //spain
    { 
        label:<div className="flex items-center"><img className="w-6 mr-2" src="/images/flags/flags5.png" /> ESPAÑA</div>, 
        value:{
            locale: "es",
            messages: es_tl,
            country: "ESPAÑA",
            flag: "/images/flags/flags5.png"
        }
    },
    //france
    {
        label:<div className="flex items-center"><img className="w-6 mr-2" src="/images/flags/flags4.png" /> FRANCE</div>, 
        value:{
            locale: "fr",
            messages: fr_tl,
            country: "FRANCE",
            flag: "/images/flags/flags4.png"
        }
    },
    //italy
    { 
        label:<div className="flex items-center"><img className="w-6 mr-2" src="/images/flags/flags.png" /> ITALIA</div>, 
        value:{
            locale: "it",
            messages: it_tl,
            country: "ITALIA",
            flag: "/images/flags/flags.png"
        }
    },
    //netherlands
    { 
        label:<div className="flex items-center"><img className="w-6 mr-2" src="/images/flags/flags8.png" /> NEDERLAND</div>, 
        value:{
            locale: "ne",
            messages: ne_tl,
            country: "NEDERLAND",
            flag: "/images/flags/flags8.png"
        }
    },
    //other coutries
    {
        label:<div className="flex items-center">OTHER COUNTRIES</div>, 
        value:{
            locale: "en",
            messages: en_tl,
            country: "OTHER COUNTRIES",
            flag: ""
        }
    }/*,
    {
        label:<div className="flex items-center"><img className="w-6 mr-2" src="/images/flags/flags6.png" /> USA</div>, 
        value:{
            locale: "us",
            messages: en_tl,
            country: "USA"
        }
    },*/
]


export const defaultCountryPatientInformationIndex = 7;

export const countryPatientInformationArray = [
    //bulgaria
    {
            
        label:<div className="flex items-center"><img className="w-6 mr-2" src="/images/flags/flags_bulgaria.png" /> БЪЛГАРИЯ</div>, 
        value:{
            locale: "bg",
            country: "БЪЛГАРИЯ",
            flag: "/images/flags/flags_bulgaria.png"
        }
    },
    //greece
    {
        label:<div className="flex items-center"><img className="w-6 mr-2" src="/images/flags/flags_grecia.png" /> ΕΛΛΑΔΑ</div>, 
        value:{
            locale: "el",
            country: "ΕΛΛΑΔΑ",
            flag: "/images/flags/flags_grecia.png"
        }
    },
    //australia
    {
        label:<div className="flex items-center"><img className="w-6 mr-2" src="/images/flags/flags9.png" /> AUSTRALIA</div>, 
        value:{
            locale: "au",
            country: "AUSTRALIA",
            flag: "/images/flags/flags9.png"
        }
    },
    //czech republic
    {
        label:<div className="flex items-center"><img className="w-6 mr-2" src="/images/flags/flags_repubblicaceca.png" /> ČESKÁ REPUBLIKA</div>, 
        value:{
            locale: "cs",
            country: "ČESKÁ REPUBLIKA",
            flag: "/images/flags/flags_repubblicaceca.png"
        }
    },
    //denmark
    {
        label:<div className="flex items-center"><img className="w-6 mr-2" src="/images/flags/flags_danimarca.png" /> DANMARK</div>, 
        value:{
            locale: "da",
            country: "DANMARK",
            flag: "/images/flags/flags_danimarca.png"
        }
    },
    //germany
    {
        label:<div className="flex items-center"><img className="w-6 mr-2" src="/images/flags/flags3.png" /> DEUTSCHLAND</div>, 
        value:{
            locale: "de",
            country: "DEUTSCHLAND",
            flag: "/images/flags/flags3.png"
        }
    },
    //estonia
    {
        label:<div className="flex items-center"><img className="w-6 mr-2" src="/images/flags/flags_estonia.png" /> EESTI</div>, 
        value:{
            locale: "et",
            country: "EESTI",
            flag: "/images/flags/flags_estonia.png"
        }
    },
    //england
    {
        label:<div className="flex items-center"><img className="w-6 mr-2" src="/images/flags/flags7.png" /> UNITED KINGDOM</div>, 
        value:{
            locale: "en",
            country: "UNITED KINGDOM",
            flag: "/images/flags/flags7.png"
        }
    },
    //spain
    {
        label:<div className="flex items-center"><img className="w-6 mr-2" src="/images/flags/flags5.png" /> ESPAÑA</div>, 
        value:{
            locale: "es",
            country: "ESPAÑA",
            flag: "/images/flags/flags5.png"
        }
    },
    //france
    {
        label:<div className="flex items-center"><img className="w-6 mr-2" src="/images/flags/flags4.png" /> FRANCE</div>, 
        value:{
            locale: "fr",
            country: "FRANCE",
            flag: "/images/flags/flags4.png"
        }
    },
    //croatia
    {
        label:<div className="flex items-center"><img className="w-6 mr-2" src="/images/flags/flags_croazia.png" /> HRVATSKA</div>, 
        value:{
            locale: "hr",
            country: "HRVATSKA",
            flag: "/images/flags/flags_croazia.png"
        }
    },
    //italy
    {
        label:<div className="flex items-center"><img className="w-6 mr-2" src="/images/flags/flags.png" /> ITALIA</div>, 
        value:{
            locale: "it",
            country: "ITALIA",
            flag: "/images/flags/flags.png"
        }
    },
    //latvia
    {
        label:<div className="flex items-center"><img className="w-6 mr-2" src="/images/flags/flags_lettonia.png" /> LATVIJA</div>, 
        value:{
            locale: "lv",
            country: "LATVIJA",
            flag: "/images/flags/flags_lettonia.png"
        }
    },
    //lithuania
    {
        label:<div className="flex items-center"><img className="w-6 mr-2" src="/images/flags/flags_lituania.png" /> LIETUVA</div>, 
        value:{
            locale: "lt",
            country: "LIETUVA",
            flag: "/images/flags/flags_lituania.png"
        }
    },  
    //hungary
    {
        label:<div className="flex items-center"><img className="w-6 mr-2" src="/images/flags/flags_ungheria.png" /> MAGYARORSZÁG</div>, 
        value:{
            locale: "hu",
            country: "MAGYARORSZÁG",
            flag: "/images/flags/flags_ungheria.png"
        }
    },  
    //netherlands
    {
        label:<div className="flex items-center"><img className="w-6 mr-2" src="/images/flags/flags8.png" /> NEDERLAND</div>, 
        value:{
            locale: "nl",
            country: "NEDERLAND",
            flag: "/images/flags/flags8.png"
        }
    },    
    //norway
    {
        label:<div className="flex items-center"><img className="w-6 mr-2" src="/images/flags/flags_norvegia.png" /> NORGE</div>, 
        value:{
            locale: "no",
            country: "NORGE",
            flag: "/images/flags/flags_norvegia.png"
        }
    },    
    //poland
    {
        label:<div className="flex items-center"><img className="w-6 mr-2" src="/images/flags/flags_polonia.png" /> POLSKA</div>, 
        value:{
            locale: "pl",
            country: "POLSKA",
            flag: "/images/flags/flags_polonia.png"
        }
    },   
    //portugal
    {
        label:<div className="flex items-center"><img className="w-6 mr-2" src="/images/flags/flags_portogallo.png" /> PORTUGAL</div>, 
        value:{
            locale: "pt",
            country: "PORTUGAL",
            flag: "/images/flags/flags_portogallo.png"
        }
    },
    //romania
    {
        label:<div className="flex items-center"><img className="w-6 mr-2" src="/images/flags/flags_romania.png" /> ROMÂNIA</div>, 
        value:{
            locale: "ro",
            country: "ROMÂNIA",
            flag: "/images/flags/flags_romania.png"
        }
    },
    //slovenija
    {
        label:<div className="flex items-center"><img className="w-6 mr-2" src="/images/flags/flags_slovenia.png" /> SLOVENIJA</div>, 
        value:{
            locale: "sl",
            country: "SLOVENIJA",
            flag: "/images/flags/flags_slovenia.png"
        }
    },
    //slovakia
    {
        label:<div className="flex items-center"><img className="w-6 mr-2" src="/images/flags/flags_slovacchia.png" /> SLOVENSKO</div>, 
        value:{
            locale: "sk",
            country: "SLOVENSKO",
            flag: "/images/flags/flags_slovacchia.png"
        }
    },
    //finland
    {
        label:<div className="flex items-center"><img className="w-6 mr-2" src="/images/flags/flags_finlandia.png" /> SUOMI</div>, 
        value:{
            locale: "fi",
            country: "SUOMI",
            flag: "/images/flags/flags_finlandia.png"
        }
    },
    //sweden
    {
        label:<div className="flex items-center"><img className="w-6 mr-2" src="/images/flags/flags_svezia.png" /> SVERIGE</div>, 
        value:{
            locale: "sv",
            country: "SVERIGE",
            flag: "/images/flags/flags_svezia.png"
        }
    },   
    //turkey
    {
        label:<div className="flex items-center"><img className="w-6 mr-2" src="/images/flags/flags_turkey.png" /> TÜRKIYE</div>, 
        value:{
            locale: "tr",
            country: "TÜRKIYE",
            flag: "/images/flags/flags_turkey.png"
        }
    },
    //russia
    {
        label:<div className="flex items-center"><img className="w-6 mr-2" src="/images/flags/flags_russia.png" /> Россия</div>, 
        value:{
            locale: "ru",
            country: "Россия",
            flag: "/images/flags/flags_russia.png"
        }
    }  
]


export const POPUP_SMALL = "small";
export const POPUP_MEDIUM = "medium";
export const POPUP_LARGE = "large";


// Old Production URL
// export const BASE_URL = "https://corcym.astutesoftwares00.com/";

// latest
export const BASE_URL = "https://apicorcym.astutesoftwares.com/";

// Ngrok
// export const BASE_URL = "https://4ade-79-127-239-135.ngrok-free.app/";

// Local
// export const BASE_URL = "http://192.168.10.16:8012/";



